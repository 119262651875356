import React from "react"
import * as style from "../styles/Footer.module.scss"

function Footer() {
    return (
        <footer className={style.footer}>
            Contact:
            <a href="mailto:contact@marsgamedev.com">&#32;contact@marsgamedev.com</a>
            <br />
            &copy; Mars Game Development {new Date().getFullYear()}
        </footer>
    )
}
export default Footer