@use "global.module.scss" as *;

.playlist {
    margin: 60px 12px 0 12px;

    // 16:9 aspect ratio
    iframe {
        margin: 0;
        max-width: 1200px;
        max-height: 720px;
        width: 100vw;
        height: 56.25vw;
        border: none;
        user-select: none;
    }

    @media (max-width: 772px) {
        iframe {
            width: 100vw;
        }
    }
}

.video {
    margin: 18px 0 12px 0;
}