import React from "react"
import * as globalStyle from "../styles/global.module.scss"
import Game from "../hooks/Game"

export default function MarsTrip() {
    return (
        <>
            <Game url="https://itch.io/embed-upload/7284505?color=000000" width="606" height="826" />
            <div className={globalStyle.info}>
                <ul>
                    <p>Instructions</p>
                    <li>Collect Coins</li>
                    <li>Shoot asteroids</li>
                    <li>Collect the Mars Rovers</li>
                    <li>Dodge moons, UFOs and buildings</li>
                </ul>
                <ul>
                    <p>Controls</p>
                    <li>Shoot - Spacebar</li>
                    <li>Move - WASD or Arrow Keys</li>
                </ul>
            </div>
        </>
    )
}