import React from 'react'
import * as globalStyle from "../styles/global.module.scss"
import Game from '../hooks/Game'

function DogeRocket() {
    return (
        <>
            <Game url="https://itch.io/embed-upload/7284513?color=464646" width="606" height="826" />
            <div className={globalStyle.info}>
                <ul>
                    <p>Instructions:</p>
                    <li>Collect Dogecoins</li>
                    <li>Shoot asteroids</li>
                    <li>Collect the moon rovers</li>
                </ul>
                <ul>
                    <p>Controls:</p>
                    <li>Shoot - Spacebar</li>
                    <li>Move - WASD or Arrow Keys</li>
                </ul>
            </div>
        </>
    )
}
export default DogeRocket