import React from "react"
import * as style from "../styles/About.module.scss"
import * as globalStyle from "../styles/global.module.scss"
import iPhone1 from "../assets/phone1.jpg"
import iPhone2 from "../assets/phone2.jpg"
import { Timeline } from "react-twitter-widgets"

export default function About() {
    return (
        <>
            <p className={globalStyle.paragraph}>
                Mars Game Development is an independent game development company
                created by I, Justin Marotta. What drives my development of games is
                my passion for programming, design and music. All programming, art and
                musical compositions are done by myself. Be sure to check out the
                YouTube channel above and follow me on the social media links below to
                never miss an upload, or update on my game development.
            </p>

            <div className={style.images}>
                <img className={globalStyle.screenshot} src={iPhone1} alt="iPhone screen capture 1"></img>
                <img className={globalStyle.screenshot} src={iPhone2} alt="iPhone screen capture 2"></img>
            </div>
        </>
    )
}