@use "global.module.scss" as *;

.nav {
    margin: 30px 30px 0 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    user-select: none;
}

.mobileContainer {
    overflow: hidden;
    position: absolute;
    top: 60px;
    right: 0;
    pointer-events: none;
    user-select: none;

    .mobileNav {
        background-color: rgb(60, 60, 60);
        position: relative;
        right: -200px;
        display: flex;
        flex-direction: column;
        text-align: center;
        max-width: 200px;
        border-radius: 12px;
        pointer-events: all;
        z-index: 2;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
        transition: right .2s ease-in-out;
    }
}

.link {
    color: $primaryColor;
    margin: 12px;
    padding: 6px 12px;
    text-align: center;
    text-decoration: none;
    font-size: 22px;
    font-weight: bold;
    border: 1px solid $primaryColor;
    border-radius: 8px;
    width: 180px;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
        background-color: $primaryColor;
        color: black;
        scale: 1.08;
    }
}

.icon {
    color: $primaryColor;
    font-size: 18pt;
    position: absolute;
    top: 21px;
    right: 21px;
    transition: all .2s ease-in-out;

    &:hover {
        scale: 1.2;
    }
}