import React from "react"
import { useState, useEffect, useCallback } from "react"
import { Outlet } from "react-router-dom"
import * as style from "../styles/Layout.module.scss"
import Header from "../hooks/Header"
import Nav from "./Nav"
import Footer from "../hooks/Footer"
import sb from 'satoshi-bitcoin'
import StarfieldAnimation from 'react-starfield-animation'
import MyDoge from "./MyDoge"

export default function Layout() {
    return (
        <>
            <StarfieldAnimation
                style={{
                    position: 'fixed',
                    width: '100vw',
                    height: '100vh',
                    zIndex: -1,
                }}
            />
            <div className={style.layout}>
                <div className={style.content}>
                    <Header />
                    <MyDoge />
                    <Nav />
                    <Outlet />
                </div>
                <Footer />
            </div>
        </>
    )
}