import React from "react"
import { useEffect } from "react"
import { useLocation } from 'react-router-dom'
import * as style from "../styles/Header.module.scss"

function Header() {
    const location = useLocation()
    const [title, setTitle] = React.useState("MARS GAME DEVELOPMENT")

    useEffect(() => {
        setTitle(getTitleByPathname(location.pathname));
    }, [location])

    return (
        <header className={style.header}>{title}</header>
    )

    function getTitleByPathname(pathname: string) {
        switch (pathname) {
            case "/":
                return "MARS GAME DEVELOPMENT"
            case "/marstrip":
                return "MARS TRIP"
            case "/marsrpg":
                return "MARS MINER"
            case "/dogerocket":
                return "DOGE ROCKET"
            case "/about":
                return "ABOUT"
        }
    }
}
export default Header
