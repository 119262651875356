import React from "react"
import { useState } from "react"
import * as style from "../styles/Icon.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function Icon(props) {
    return (
        <a className={style.icon} href={props.url}>
            <FontAwesomeIcon icon={props.icon} color={props.color} />
        </a>
    )
}
export default Icon