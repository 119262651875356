@use "global.module.scss" as *;

.header {
    font-family: ultra, Arial, Helvetica, sans-serif;
    margin: 80px 12px 0 12px;
    font-size: 48px;
    color: $primaryColor;
    text-align: center;

    @media (max-width: 425px) {
        font-size: 36px;
    }
}