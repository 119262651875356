import React from "react"
import * as style from "../styles/Home.module.scss"
import * as globalStyle from "../styles/global.module.scss"
import { faApple, faGooglePlay, faItchIo, faXTwitter, faInstagram, faFacebook, faYoutube, faPatreon } from "@fortawesome/free-brands-svg-icons"
import { faBolt } from "@fortawesome/free-solid-svg-icons"
import Header from "../hooks/Header"
import Icon from "../hooks/Icon"
import { Tweet, Timeline, Follow } from "react-twitter-widgets"

export default function Home() {
  return (
    <>
      <div className={style.playlist}>
        <iframe
          src="https://www.youtube.com/embed/videoseries?list=PLjmEfMBS8hI_QTb3DsUOAKZZx_zLzIFOf"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture">
        </iframe>
      </div>
      <div className={globalStyle.socialLinks}>
        <Icon icon={faApple} color="white" url="https://apps.apple.com/us/developer/justin-marotta/id1495600336" />
        <Icon icon={faGooglePlay} color="#78c257" url="https://play.google.com/store/apps/developer?id=Mars+Game+Development" />
        <Icon icon={faItchIo} color="#ff1854" url="https://marsgamedev.itch.io/" />
        <Icon icon={faXTwitter} color="white" url="https://x.com/mars_gamedev" />
        <Icon icon={faYoutube} color="#ff0000" url="https://www.youtube.com/channel/UCTqkKCcs1aFqX5K_VyyaohQ" />
        <Icon icon={faBolt} color="#7bca33" url="https://gamejolt.com/@marsgamedev" />
        <Icon icon={faInstagram} color="#e1306c" url="https://instagram.com/mars_gamedev" />
        <Icon icon={faFacebook} color="#3b5998" url="https://facebook.com/marsgamedev" />
        {/* <Icon icon={faPatreon} color="#f96854" url="https://patreon.com/marsgamedev" /> */}
      </div>
      <div className={style.video}>
        <Tweet tweetId="1725636169780625555"
          options={{ theme: "dark", align: "center" }}
        ></Tweet>
        <div className={globalStyle.follow}>
          <Follow username="mars_gamedev"></Follow>
        </div>
      </div>
      {/* <div className={globalStyle.timeline}>
        <Timeline
          dataSource={{
            sourceType: "profile",
            screenName: "mars_gamedev"
          }}
          options={{
            height: "600",
            width: "300",
            theme: "dark"
          }}
        />
      </div> */}
    </>
  )
}