@use "global.module.scss" as *;

.container {
    margin: 0;
    position: absolute;
    top: 21px;
    left: 21px;
    cursor: pointer;
    user-select: none;
}

.support {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tipButton {
    background-color: rgba(white, 0);
    color: $primaryColor;
    font-size: 9pt;
    font-weight: bold;
    margin: 0;
    padding: 6px 12px;
    border: 2px solid $primaryColor;
    border-radius: 6px;

    &:hover {
        background-color: $primaryColor;
        color: black;
        scale: 1.08;
        transition: all .2s ease-in-out;
    }
}

.myDogeImage {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 6px;
}

.text {
    margin: 0 0 0 12px;
    color: $primaryColor;
    font-size: 15pt;
}