import React, { useEffect } from "react"
import Icon from "./Icon"
import * as style from "../styles/Game.module.scss"
import * as globalStyle from "../styles/global.module.scss"
import { faApple, faGooglePlay, faItchIo, faXTwitter, faInstagram, faFacebook, faYoutube, faPatreon } from "@fortawesome/free-brands-svg-icons"
import { faBolt } from "@fortawesome/free-solid-svg-icons"


export default function Game(props: any) {
    const [url, setUrl] = React.useState(props.url)
    const [width, setWidth] = React.useState(props.width)
    const [height, setHeight] = React.useState(props.height)
    let scale = 1.0

    useEffect(() => {
        function handleResize() {
            const game = document.getElementsByClassName(style.game)[0] as HTMLElement
            const newScale = window.innerWidth / game.clientWidth
            if (newScale > 1) scale = 1
            else scale = newScale

            game.style.transform = `scale(${scale})`
            game.style.marginRight = `-${width - width * scale}px`
            game.style.marginBottom = `${-(height - height * scale) + 60}px`
        }
        handleResize()
        window.addEventListener("resize", handleResize)
    }, [])

    return (
        <>
            <div className={style.game}>
                <iframe src={url} width={width} height={height}></iframe>
            </div>
            <div className={globalStyle.socialLinks}>
                <Icon icon={faApple} color="white" url="https://apps.apple.com/us/developer/justin-marotta/id1495600336" />
                <Icon icon={faGooglePlay} color="#78c257" url="https://play.google.com/store/apps/developer?id=Mars+Game+Development" />
                <Icon icon={faItchIo} color="#ff1854" url="https://marsgamedev.itch.io/" />
                <Icon icon={faXTwitter} color="white" url="https://x.com/mars_gamedev" />
                <Icon icon={faYoutube} color="#ff0000" url="https://www.youtube.com/channel/UCTqkKCcs1aFqX5K_VyyaohQ" />
                <Icon icon={faBolt} color="#7bca33" url="https://gamejolt.com/@marsgamedev" />
                <Icon icon={faInstagram} color="#e1306c" url="https://instagram.com/mars_gamedev" />
                <Icon icon={faFacebook} color="#3b5998" url="https://facebook.com/marsgamedev" />
                {/* <Icon icon={faPatreon} color="#f96854" url="https://patreon.com/marsgamedev" /> */}
            </div>
            <div style={{ height: "60px" }}></div>
        </>
    )
}