@use "global.module.scss" as *;

.footer {
    color: $primaryColor;
    margin: 0 0 30px 0;
    text-align: center;
    font-size: 20px;
    flex-shrink: 0;

    @media (max-width: 425px) {
        font-size: 15px;
    }
}