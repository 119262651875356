import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import * as style from "../styles/Nav.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { faX } from "@fortawesome/free-solid-svg-icons"
import { isMobile } from "react-device-detect"

export default function Nav() {
    const [isNavOpen, setIsNavOpen] = useState(false)
    const [isMobileClient, setIsMobileClient] = useState<boolean>(!isMobile)

    useEffect(() => {
        if (isMobile) {
            setIsMobileClient(true)
            return
        }
        function handleResize() {
            if (window.innerWidth <= 675) {
                setIsMobileClient(true)
            } else {
                setIsMobileClient(false)
            }
        }
        handleResize()
        window.addEventListener('resize', handleResize)
    }, [])

    function toggleNav() {
        const nav = document.getElementsByClassName(style.mobileNav)[0] as HTMLElement
        setIsNavOpen(!isNavOpen)

        if (!isNavOpen) nav.style.right = "0"
        else nav.style.right = "-215px"
    }

    function closeNav() {
        const nav = document.getElementsByClassName(style.mobileNav)[0] as HTMLElement
        setIsNavOpen(false)
        nav.style.right = "-215px"
    }

    return (
        <>
            {isMobileClient && !isNavOpen && <FontAwesomeIcon id="navOpen" className={style.icon} icon={faBars} onClick={toggleNav} />}
            {isMobileClient && isNavOpen && <FontAwesomeIcon id="navClose" className={style.icon} icon={faX} onClick={toggleNav} />}

            {
                isMobileClient && <div className={style.mobileContainer}>
                    <nav className={style.mobileNav}>
                        <Link className={style.link} to="/" onClick={closeNav}>Home</Link>
                        <Link className={style.link} to="/marstrip" onClick={closeNav}>Mars Trip</Link>
                        <Link className={style.link} to="/marsrpg" onClick={closeNav}>Mars Miner</Link>
                        <Link className={style.link} to="/dogerocket" onClick={closeNav}>Doge Rocket</Link>
                        <Link className={style.link} to="/about" onClick={closeNav}>About</Link>
                    </nav>
                </div>
            }

            {
                !isMobileClient && <nav className={style.nav}>
                    <Link className={style.link} to="/">Home</Link>
                    <Link className={style.link} to="/marstrip">Mars Trip</Link>
                    <Link className={style.link} to="/marsrpg">Mars Miner</Link>
                    <Link className={style.link} to="/dogerocket">Doge Rocket</Link>
                    <Link className={style.link} to="/about">About</Link>
                </nav>
            }
        </>
    )
}