.images {
    margin: 0 15px 30px 15px;
    padding: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    @media (max-width: 650px) {
        flex-direction: column;
        align-items: center;

        :last-child {
            margin: 0 30px;
        }
    }
}