import React from "react"
import * as globalStyle from "../styles/global.module.scss"
import Game from "../hooks/Game"

function MarsRPG() {
    return (
        <>
            <Game url="https://itch.io/embed-upload/9883371?color=000000" width="1286" height="746" />
            <div className={globalStyle.info}>
                <ul>
                    <p>Controls:</p>
                    <li>Jump - Spacebar</li>
                    <li>Move - WASD or Arrow Keys</li>
                    <li>Interact - E</li>
                    <li>Inventory - F</li>
                    <li>Item Wheel - Q</li>
                    <li>Zoom Out - V</li>
                    <li>Boost - Left Shift</li>
                </ul>
            </div>
        </>
    )
}
export default MarsRPG