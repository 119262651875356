$primaryColor: rgb(255, 144, 70);
$secondaryColor: blueviolet;

.paragraph {
    background-color: rgba(255, 255, 255, .12);
    backdrop-filter: blur(.5px);
    color: $primaryColor;
    margin: 60px 12px;
    padding: 15px 30px;
    max-width: 720px;
    font-size: 28px;
    line-height: 1.8;
    border-radius: 30px;

    @media (max-width: 425px) {
        margin: 30px;
        padding: 12px 24px;
        font-size: 18px;
        border-radius: 15px;
        width: 300px;
    }
}

.info {
    margin: 0 0 60px 0;
    text-align: center;
    color: $primaryColor;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ul {
        background-color: rgb(255, 255, 255, .12);
        margin: 0 0 60px 0;
        padding: 10px;
        min-width: 320px;
        max-width: 425px;
        text-align: left;
        border: 2px solid $primaryColor;
        border-radius: 10px;

        li {
            margin: 0 0 0 15px;
        }

        p {
            margin: 0 0 15px 0;
            font-size: 18px;
            text-align: center;
        }
    }

    &:last-child {
        margin: 0;
    }

    @media (max-width: 425px) {
        font-size: 15px;
        max-width: 320px;
    }
}

.screenshot {
    margin: 0 30px 60px 30px;
    width: 300px;
    height: 540px;
    image-rendering: high-quality;
    border: 1px solid $primaryColor;
    border-radius: 36px;
}

.timeline {
    margin: 0 12px 60px 12px;
}

.follow {
    margin: 30px;
}

.socialLinks {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}