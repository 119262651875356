@use "global.module.scss" as *;

.layout {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.content {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 auto;
}