import React from "react"
import { useState, useEffect, useCallback } from "react"
import sb from 'satoshi-bitcoin'
import * as style from "../styles/MyDoge.module.scss"
import myDogeImage from "../assets/mydoge.png"
import { isMobile } from "react-device-detect"

export default function MyDoge() {
    const RECIPIENT_ADDRESS = "DDpPxJj3BVBvotNpYw54tp7jgZSqUVZgN2"
    const [connected, setConnected] = useState(false)
    const [myDogeMask, setMyDogeMask] = useState<any>()

    useEffect(() => {
        function onInit() {
            const { doge } = window as any
            setMyDogeMask(doge)
            window.removeEventListener('doge#initialized', onInit)
        }
        window.addEventListener('doge#initialized', onInit, { once: true })
    }, [])

    const onConnect = useCallback(async () => {
        if (isMobile) {
            window.open("https://mydoge.com/marsgamedev", "_blank");
            return
        }

        if (!myDogeMask?.isMyDogeMask) {
            alert(`MyDogeMask not installed!`)
            return
        }

        try {
            if (connected) {
                const disconnectRes = await myDogeMask.disconnect()
                console.log('disconnect result', disconnectRes)
                if (disconnectRes.disconnected) {
                    setConnected(false)
                }
                return
            }

            const connectRes = await myDogeMask.connect()
            console.log('connect result', connectRes)
            if (connectRes.approved) {
                setConnected(true)

                const balanceRes = await myDogeMask.getBalance()
                console.log('balance result', balanceRes)

                let interval = setInterval(async () => {
                    const connectionStatusRes = await myDogeMask
                        .getConnectionStatus()
                        .catch(console.error)
                    console.log('connection status result', connectionStatusRes)

                    if (!connectionStatusRes?.connected) {
                        clearInterval(interval)
                        setConnected(false)
                    }
                }, 5000)
            }
        } catch (e) {
            console.error(e)
        }
    }, [connected, myDogeMask])

    const onTip = useCallback(async () => {
        if (!myDogeMask?.isMyDogeMask) {
            alert(`MyDogeMask not installed!`)
            window.open("https://chrome.google.com/webstore/detail/mydogemask/mljponncmhdlacmjbophphkbgcgjdnff", "_blank");
            return
        }

        if (!connected) {
            alert(`MyDogeMask not connected!`)
            return
        }

        try {
            const txReqRes = await myDogeMask.requestTransaction({
                recipientAddress: RECIPIENT_ADDRESS,
                dogeAmount: 4.20,
            })
            console.log('request transaction result', txReqRes)
            // Poll until transaction is confirmed
            let interval = setInterval(async () => {
                const txStatusRes = await myDogeMask.getTransactionStatus({
                    txId: txReqRes.txId,
                })
                console.log('transaction status result', txStatusRes)
                // Once confirmed, stop polling and update balance
                if (
                    txStatusRes.status === 'confirmed' &&
                    txStatusRes.confirmations > 1
                ) {
                    clearInterval(interval)
                    const balanceRes = await myDogeMask.getBalance()
                    console.log('balance result', balanceRes)
                    alert(`Transaction confirmed! Thank you for your support!`)
                }
            }, 10000)
        } catch (e) {
            console.error(e)
        }
    }, [connected, myDogeMask])

    return (
        <div className={style.container}>
            {!connected &&
                <div className={style.support} onClick={onConnect}>
                    <img className={style.myDogeImage} src={myDogeImage} ></img>
                    <p className={style.text}>Support me!</p>
                </div>
            }
            {connected && <button className={style.tipButton} onClick={onTip}>Tip 4.20 DOGE!</button>}
            {/* {connected && <div>Address: {address}</div>}
            {connected && <div>Balance: {balance}</div>} */}
        </div>
    )
}