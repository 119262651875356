import { Routes, Route, Link, BrowserRouter } from 'react-router-dom'
import React from 'react'
import * as style from '../styles/App.module.scss'
import Layout from './Layout'
import Home from '../pages/Home'
import MarsTrip from '../pages/MarsTrip'
import MarsRPG from '../pages/MarsRPG'
import DogeRocket from '../pages/DogeRocket'
import About from '../pages/About'

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="marstrip" element={<MarsTrip />} />
                    <Route path="marsrpg" element={<MarsRPG />} />
                    <Route path="dogerocket" element={<DogeRocket />} />
                    <Route path="about" element={<About />} />
                    {/* <Route path="*" element={<NoPage />} /> */}
                </Route> 
            </Routes>
        </BrowserRouter>
    )
}
export default App