@use "global.module.scss" as *;

.icon {
    margin: 12px 24px;
    font-size: 30pt;
    transition: all .2s ease-in-out;

    &:hover {
        scale: 1.2;
    }
}